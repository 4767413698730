import React, { Component } from "react";
import {
  formatDotDate,
  format as formatTime,
} from "../utils/formatAudioTime.js";
import coupon_empty from "coupon/coupon_empty.png";
import coupon_icon_used from "coupon/coupon_icon_used.png";
import coupon_icon_failure from "coupon/coupon_icon_failure.png";
import "./PersonCoupon.scss";
import { TicketPriceCondition } from "./CouponGift.jsx";

const HasCouponList = ({ EcouponLists, SystemTime }) => {
  // 可赠送
  let canPresent = function (coupon) {
    let presentText = "";
    if (
      coupon.state === "已过期" ||
      coupon.state === "已使用" ||
      coupon.can_present === false
    ) {
      presentText = "";
    } else {
      presentText = (
        <a
          className="present_coupon"
          href={coupon.present_url}
          style={{
            top: coupon.all_available === false ? "13px" : null,
          }}
        >
          点此赠送
        </a>
      );
    }
    return presentText;
  };

  // 已使用_已过期
  let userConsumeStatus = function (coupon) {
    let consumeStatusPic = "";
    if (coupon.state === "已使用") {
      consumeStatusPic = <img src={coupon_icon_used} alt="" />;
    } else {
      if (coupon.state === "已过期") {
        consumeStatusPic = <img src={coupon_icon_failure} alt="" />;
      }
    }
    return consumeStatusPic;
  };

  // 消费时间未到_立即使用
  let userCouponStatus = function (coupon) {
    let useStatus = "";
    if (new Date(SystemTime) < new Date(coupon.start_time)) {
      useStatus = <div className="todo_consume">（使用时间未到）</div>;
    } else {
      useStatus = <div className="immediately_consume">立即使用</div>;
    }
    return useStatus;
  };

  let title = function (coupon) {
    // if (coupon.all_available === true) {
    //   let title = "可用于所有课程报名"
    //   if (coupon.experience_available) {
    //     title = "可用于所有课程及经验讲座报名"
    //     if (coupon.market_promotion_available) {
    //       title = "可用于所有课程、经验讲座，及部分领学习好物系列活动报名"
    //     }
    //   }
    //   if (coupon.market_promotion_available) {
    //     title = "可用于所有课程，及部分领学习好物系列活动报名"
    //   }
    //   return title
    // } else if (coupon.experience_available) {
    //   let title = "可用于所有经验讲座报名"
    //   if (coupon.market_promotion_available) {
    //     title = "可用于所有经验，及部分领学习好物系列活动"
    //   }
    //   return title
    // } else if (coupon.market_promotion_available) {
    //   return "可用于部分领学习好物系列活动报名"
    // }else {
    //   return "可用于报名以下课程："
    // }
  };

  const calcCouponTimeSubtraction = (start_time, end_time) => {
    return (
      (new Date(end_time) - new Date(start_time)) /
      (24 * 3600 * 1000)
    ).toFixed(1);
  };

  const couponTimeStamp = (start_time, end_time) => {
    const startTime = formatTime(start_time, "YYYY.MM.DD HH:mm");
    const endTime = formatTime(end_time, "YYYY.MM.DD HH:mm");
    const timeStamp = `${startTime} - ${endTime}`;
    return timeStamp;
  };

  return (
    <ul>
      {EcouponLists.map((coupon, index) => (
        <li key={index}>
          <a href={coupon.use_url ? coupon.use_url : "javascript:void(0)"}>
            <div
              className="coupon_ticket"
              style={{
                height:
                  (!coupon.course_availables.can_buy_names || coupon.course_availables.can_buy_names.length <= 1) &&
                  coupon.rule == null
                    ? "120px"
                    : null,
              }}
            >
              <div
                className="ticket_left"
                style={{
                  backgroundColor:
                    coupon.state === "已使用" ||
                    new Date(SystemTime) > new Date(coupon.end_time)
                      ? "#ddd"
                      : null,
                }}
              >
                <TicketPriceCondition
                  price={coupon.price}
                  minProductPrice={coupon.min_product_price}
                  half_price={coupon.half_price}
                />
              </div>
              <div className="ticket_right">
                {coupon.name && (
                  <div
                    className="name"
                    style={{
                      paddingRight: coupon.can_present === true ? "65px" : null,
                    }}
                  >
                    {coupon.name}
                  </div>
                )}
                <div className="ticket_list">
                  <div
                    className="ticket_desc"
                    style={{
                      paddingBottom:
                        coupon.course_availables.can_buy_names && coupon.course_availables.can_buy_names.length > 0
                          ? null
                          : "10px",
                    }}
                  >
                    <div
                      className={
                        coupon.all_available === true
                          ? "text_over_available"
                          : "text"
                      }
                      style={{
                        paddingRight:
                          coupon.can_present === true ? "65px" : null,
                      }}
                    >
                      {
                        // title(coupon)
                        coupon.title
                      }
                    </div>
                  </div>
                  {coupon.course_availables.can_buy_names && coupon.course_availables.can_buy_names.length > 0 && (
                    <div className="ticket_name">
                      {coupon.course_availables.can_buy_names.map((name, idx) => (
                        <p key={idx}>{name}</p>
                      ))}
                    </div>
                  )}
                </div>

                <div className="ticket_discount_status">
                  <div className="date">
                    {calcCouponTimeSubtraction(
                      coupon.start_time,
                      coupon.end_time
                    ) <= "1.0" ? (
                      couponTimeStamp(coupon.start_time, coupon.end_time)
                    ) : (
                      <>
                        {formatDotDate(coupon.start_time)} -{" "}
                        {formatDotDate(coupon.end_time)}
                      </>
                    )}
                  </div>
                  {coupon.state === "已使用" || coupon.state === "已过期" ? (
                    ""
                  ) : (
                    <div
                      className={`consume_status ${
                        calcCouponTimeSubtraction(
                          coupon.start_time,
                          coupon.end_time
                        ) <= "1.0"
                          ? "consume_status_right"
                          : ""
                      }`}
                    >
                      {userCouponStatus(coupon)}
                    </div>
                  )}
                </div>

                {coupon.rule && (
                  <div className="present_describe">{coupon.rule}</div>
                )}

                {userConsumeStatus(coupon) && (
                  <div className="no_valid_ticket">
                    {userConsumeStatus(coupon)}
                  </div>
                )}
                {(coupon.state === "已使用" || coupon.state === "已过期") && (
                  <div className="ovelay_style"></div>
                )}
              </div>
            </div>
          </a>
          {canPresent(coupon)}
        </li>
      ))}
    </ul>
  );
};

const UnCoupon = () => {
  return (
    <div className="un_coupon_box">
      <div className="coupon_info">
        <img src={coupon_empty} alt="" />
        <span>您还没有优惠券</span>
      </div>
      <div className="coupon_text">
        <h3>您可通过以下方式获得优惠券</h3>
        <p>1. 完成课程学习打卡等相关功能；</p>
        <p>2. 请求有优惠券的朋友赠送给您；</p>
        <p>3. 参与『心芽学堂』不定时举办的活动；</p>
        <p>4. 系统赠送。</p>
      </div>
    </div>
  );
};

export default class PersonCoupon extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    const { ecoupons, system_time } = this.props;

    return (
      <div className="comp_person_coupon">
        {ecoupons && ecoupons.length > 0 ? (
          <HasCouponList EcouponLists={ecoupons} SystemTime={system_time} />
        ) : (
          <UnCoupon />
        )}
      </div>
    );
  }
}
