import "core-js/stable";
import "regenerator-runtime/runtime";
import "./native_bridges/native_bridge";
import "./include_packages.js";
import "../packs/stylesheet/application.scss";
import "./page_audit.js";

var componentRequireContext = require.context(
  "components",
  true,
  /(batch_ecoupons\/)/
);
var ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);
