/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";
import CouponTicket from "./CouponTicket";
import { mapPropsToCounponState, getCouponRedirectUrl } from "./DrawCoupon";
import { WechatFollow } from "../study_package_activities/WechatFollow";

const SingleDrawCoupon = (props) => {
  const [isShowQRCode, setShowQRCode] = useState(false);
  const { batch_ecoupon, ecoupon, qrcode_url } = props;
  const redirectUrl = getCouponRedirectUrl(ecoupon);
  const alertQRCode = () => setShowQRCode((pre) => !pre);
  const propsToMapToCoupon = mapPropsToCounponState(props);
  return (
    <>
      <CouponTicket
        getEcouponUrl={batch_ecoupon.get_ecoupon_url}
        endDate={batch_ecoupon.end_date.replace(/-/g, ".")}
        startDate={batch_ecoupon.start_date.replace(/-/g, ".")}
        vaildAll={batch_ecoupon.all_available}
        validCourses={batch_ecoupon.can_buies}
        vaildExperiences={batch_ecoupon.experience_available}
        title={batch_ecoupon.title}
        mode={batch_ecoupon.mode}
        periodInterval={batch_ecoupon.period_interval}
        {...propsToMapToCoupon}
        redirectUrl={redirectUrl}
        QRCodeUrl={qrcode_url}
        alertQRCode={alertQRCode}
        minProductPrice={batch_ecoupon.min_product_price}
      />
      {isShowQRCode && (
        <WechatFollow
          title={"请先关注公众号后再领取"}
          qrcodeUrl={qrcode_url}
          alertQRCode={alertQRCode}
        />
      )}
    </>
  );
};

const StyledVipDrawCoupon = styled.div`
  margin-top: 35px;
  .title {
    font-size: 18px;
    color: #594a10;
    text-align: center;
  }
`;

const VipDrawCoupon = (props) => {
  const { batch_ecoupons } = props;

  console.log(props);
  return (
    <StyledVipDrawCoupon>
      <p className="title">老用户专属续课优惠券</p>
      {batch_ecoupons.map((batch_ecoupon, index) => (
        <SingleDrawCoupon
          {...props}
          batch_ecoupon={batch_ecoupon.batch_ecoupon}
          ecoupon={batch_ecoupon.ecoupon}
          key={index}
        />
      ))}
    </StyledVipDrawCoupon>
  );
};

export default VipDrawCoupon;
