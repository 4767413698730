import React from "react";
import "./DrawCoupon.scss";
import Divider from "../utils/content_divider/Divider";
import URI from "jsuri";
import UserAgent from "../utils/UserAgent";
import { onMiniProgram, convertURLToJSON } from "../utils/helpers";
import QueryString from "query-string";
import { UnboughtStudyPackageActivities } from "../study_package_activities/StudyPackageActivityList";
import CouponTicket from "./CouponTicket";
import { WechatFollow } from "../study_package_activities/WechatFollow";

// import alertQR from 'qrcode_teacher_alert.png'

const doResortActivities = function (props) {
  let boughtActivities = [];
  let unboughtActivities = [];
  let closedActivities = [];
  let unClosedActivities = [];
  let status = "";
  let initialId = 1;
  props.activities.forEach((activity, idx) => {
    let current_time = new Date(props.study_package_system_time);
    let start_time = new Date(activity.start_time);
    let end_time = new Date(activity.end_time);
    let groupon_end_time = new Date(activity.groupon_end_time);
    if (
      activity.bought_state === "bought" ||
      activity.bought_state === "pre_paid"
    ) {
      if (current_time < start_time && activity.bought_state === "bought") {
        status = "等待开课";
        unClosedActivities.push(activity);
      } else if (activity.bought_state == "pre_paid") {
        status = "等待拼团成功";
        unClosedActivities.push(activity);
      } else {
        if (current_time > end_time) {
          status = "已完课";
          closedActivities.push(activity);
        } else {
          status = "上课中";
          if (activity.buy_format == "bargain") {
            status = "去练习";
          }
          unClosedActivities.push(activity);
        }
      }
    } else {
      if (current_time > groupon_end_time) {
        status = "等待下期";
        unboughtActivities.push(activity);
      } else {
        status = "未购买";
        unboughtActivities.push(activity);
      }
    }
    activity["shortId"] = "activity" + (idx + 1);
    activity.comments
      ? activity.comments.forEach((comment) => {
          comment["shortId"] = initialId + "";
          initialId++;
        })
      : "";
    activity.buy_url = new URI(activity.buy_url).addQueryParam("ref", "home");
    activity["status"] = status;
    activity["study_package_system_time"] = props.study_package_system_time;
    activity["free"] = props.free;
    activity["user"] = props.user;
    // activity['onMiniProgramClick'] = (e) => activityTap(e)
  });
  boughtActivities = [...unClosedActivities, ...closedActivities];
  return { boughtActivities, unboughtActivities };
};

// Get the state of coupon to CouponTicket Component
const getCounponActionState = function (props) {
  let {
    study_package_system_time,
    from_user,
    ecoupon,
    user,
    is_wuhan_promotion,
    is_wuhan_area,
  } = props;
  let { pickup_valid_time, amount, end_date, is_can_get } = props.batch_ecoupon;

  study_package_system_time = new Date(study_package_system_time);
  pickup_valid_time = new Date(pickup_valid_time);
  end_date = new Date(end_date);

  //别人领取
  if (from_user) {
    return "CollectFromFriend";
  }

  // 超过优惠券使用时间
  if (ecoupon && ecoupon.current_state === "已过期") {
    return "ExcessValidity";
  }

  // 已使用
  if (ecoupon && ecoupon.current_state === "已使用") {
    return "TicketUsed";
  }

  // 已拥有&可以使用
  if (ecoupon && ecoupon.user_id === ecoupon.pickup_user_id) {
    return "CollectNow";
  }

  // 已领取&赠送给了他人
  if (ecoupon && user.id === ecoupon.pickup_user_id) {
    return "TicketSent";
  }

  // 已领完
  if (amount <= 0) {
    return "NoMoreTickets";
  }

  // 未到领取时间
  if (study_package_system_time < pickup_valid_time) {
    return "NotTimeToCollect";
  }

  // 无领取权限
  if (!is_can_get) {
    return "NoAuthorityToCollect";
  }

  // 仅限武汉地区用户领取
  if (is_wuhan_promotion && !is_wuhan_area) {
    return "OnlyWuhanTickets";
  }

  return "CollectNow";
};

// Get the force required params of current coupon state
export const mapPropsToCounponState = function (props) {
  const {
    batch_ecoupon,
    limit_owner_names,
    activities,
    from_user,
    user,
    ecoupon,
    study_package_system_time,
    is_wuhan_promotion,
    is_wuhan_area,
  } = props;

  const getCouponDrawedState = function (ecoupon, user) {
    if (ecoupon) {
      if (ecoupon.user_id === user.id) {
        return true;
      }
    }
    return false;
  };

  let state = getCounponActionState(props);

  const counponState = {
    ExcessValidity: () => ({
      actionState: state,
      sum: batch_ecoupon.price,
    }),
    CollectNow: () => ({
      actionState: state,
      ticketDrawed: getCouponDrawedState(props.ecoupon, props.user),
      ticketAmount: batch_ecoupon.amount,
      sum: batch_ecoupon.price,
    }),
    TicketUsed: () => ({
      actionState: state,
      sum: batch_ecoupon.price,
    }),
    NoMoreTickets: () => ({
      actionState: state,
      sum: batch_ecoupon.price,
    }),
    OnlyWuhanTickets: () => ({
      actionState: state,
      sum: batch_ecoupon.price,
    }),
    NotTimeToCollect: () => ({
      actionState: state,
      startSellTime: batch_ecoupon.pickup_valid_time
        .replace(/T/, " ")
        .substring(0, 16),
      sum: batch_ecoupon.price,
    }),
    TicketSent: () => ({
      actionState: state,
      sum: batch_ecoupon.price,
    }),
    NoAuthorityToCollect: () => ({
      actionState: state,
      limitOwnerNames: batch_ecoupon.limit_owner_names,
      sum: batch_ecoupon.price,
    }),
    CollectFromFriend: () => {
      let ticketDrawed = false;
      let getCollectFromFriendState = function () {
        if (
          new Date(study_package_system_time) > new Date(batch_ecoupon.end_time)
        ) {
          return "OutOfDate";
        }
        // 领券的人已经使用了券，赠券和领券的人再次进入页面
        if (ecoupon && ecoupon.current_state === "已使用") {
          return "Used";
        }
        // 自己进来 -- 已被领取
        if (from_user.id === user.id && ecoupon.user_id !== from_user.id) {
          return "OutOfUse";
        }
        // 自己进来 -- 未被领取
        if (from_user.id === user.id && ecoupon.user_id === from_user.id) {
          return "Self";
        }
        // 券已经被领取后，第三人进入
        // 被领取过了 && 进来的用户不是券的主人
        if (ecoupon.user_id !== from_user.id && user.id !== ecoupon.user_id) {
          return "OutOfUse";
        }
        // 已经领取的用户再次进来
        // 进来的用户是券的主人 && 进来的用户不是原来赠送券的人
        if (ecoupon.user_id === user.id && user.id !== from_user.id) {
          ticketDrawed = true;
          return "Success";
        }
        // 根据上几步，这里是可以领取的条件
        // 券的主人是赠送券的人
        // 如果是 券的主人又是赠送券的人 来领，则'Self'
        if (ecoupon.user_id === from_user.id) {
          return "Success";
        }
      };
      return {
        sum: ecoupon.price,
        actionState: state,
        fromUser: from_user,
        collectFromFriendState: getCollectFromFriendState(),
        ticketDrawed: ticketDrawed,
        canPresent: batch_ecoupon.can_present,
      };
    },
  };
  return counponState[state]();
};

export const getCouponRedirectUrl = function (ecoupon) {
  if (ecoupon) {
    if (ecoupon.redirect_url) {
      return ecoupon.redirect_url;
    }
  }
  return window.location.href;
};

export default class DrawCoupon extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      ...doResortActivities(props),
      propsToMapToCoupon: mapPropsToCounponState(props),
      isShowQRCode: false,
    };

    this.activityTap = this.activityTap.bind(this);
  }

  activityTap(e) {
    // let mini_program = e.currentTarget.getAttribute("data-url")
    // if ((UserAgent.isMiniProgram()) && mini_program.includes('bargain_activities?id=2')) {
    //   e.preventDefault()
    //   wx.miniProgram.navigateTo({ url: '/pages/calculate_training/list' })
    // } else {
    //   onMiniProgram(e, mini_program, {})
    // }

    let mini_program_url = e.currentTarget.getAttribute("data-url");
    if (UserAgent.isWechat() || UserAgent.isMiniProgram()) {
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          if (mini_program_url.includes("bargain_activities?id=2")) {
            wx.miniProgram.navigateTo({
              url: "/pages/calculate_training/list",
            });
          } else {
            onMiniProgram(e, mini_program_url, {});
          }
        } else {
          window.location.href = mini_program_url;
        }
      });
    } else if (UserAgent.isWebBrowser()) {
      window.location.href = mini_program_url;
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: mini_program_url });
    }
    e.preventDefault();
  }

  alertQRCode = () => {
    this.setState((prevState) => ({
      isShowQRCode: !prevState.isShowQRCode,
    }));
  };

  componentDidMount() {
    const share_title = this.props.share_title;
    const share_description = this.props.share_description;
    const share_image_url = this.props.share_image_url;
    const share_url = this.props.share_url;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }

  render() {
    const {
      batch_ecoupon,
      qrcode_url,
      is_wuhan_promotion,
      is_wuhan_area,
    } = this.props;
    const { unboughtActivities, propsToMapToCoupon, isShowQRCode } = this.state;
    const redirectUrl = getCouponRedirectUrl(this.props.ecoupon);
    return (
      <div className="comp_activity_coupon">
        <CouponTicket
          getEcouponUrl={batch_ecoupon.get_ecoupon_url}
          endDate={batch_ecoupon.end_date.replace(/-/g, ".")}
          startDate={batch_ecoupon.start_date.replace(/-/g, ".")}
          vaildAll={batch_ecoupon.all_available}
          validCourses={batch_ecoupon.can_buies}
          vaildExperiences={batch_ecoupon.experience_available}
          title={batch_ecoupon.title}
          half_price={batch_ecoupon.half_price}
          mode={batch_ecoupon.mode}
          periodInterval={batch_ecoupon.period_interval}
          {...propsToMapToCoupon}
          redirectUrl={redirectUrl}
          QRCodeUrl={qrcode_url}
          alertQRCode={this.alertQRCode}
          isWuhanPromotion={is_wuhan_promotion}
          isWuhanArea={is_wuhan_area}
          minProductPrice={batch_ecoupon.min_product_price}
        />
        {unboughtActivities && unboughtActivities.length > 0 && (
          <Divider content={"优惠券可用于以下课程"} />
        )}
        <div className="margined">
          <UnboughtStudyPackageActivities
            UnboughtActivities={unboughtActivities}
            onMiniProgramClick={this.activityTap}
          />
        </div>
        {
          isShowQRCode && (
            <WechatFollow
              title={"请先关注公众号后再领取"}
              qrcodeUrl={qrcode_url}
              alertQRCode={this.alertQRCode}
            />
          )
          // isShowQRCode
          // &&
          // <QRCode
          //   QRCodeUrl={qrcode_url}
          //   alertQRCode={this.alertQRCode}
          // />
        }
      </div>
    );
  }
}

// const QRCode = ({ QRCodeUrl, alertQRCode }) =>
//   <div className="guide_wechat_follow show">
//     <div className="dialog_qrcode_body">
//       <div className="btn-close-wechat" onClick={alertQRCode}>&times;</div>
//       <p>请先关注公众号</p>
//       <img data-src={QRCodeUrl} className="lazyload" data-sizes="auto" />
//     </div>
//   </div>
